import React from 'react'
import styled from 'styled-components'

import Button from 'src/components/Button'
import FlexboxRow from 'src/components/FlexboxRow'

import LOGO from 'src/assets/img/logo.svg'

const Wrapper = styled(FlexboxRow).attrs({
  className: "ContactSection",
})`

  position: relative;
  height: 100vh;
  width: 100vw;
  color: var(--light-black);

  .Left {
    padding-left: 15vw;
    padding-right: 2vw;
  }

  .Right {
    padding-right: 15vw;
  }

  .Left, .Right {
    width: 50vw;
    padding-top: 37vh;
    display: inline-block;
  }

  h1 {
    font-size: 3.5rem;
    font-weight: 900;
    text-transform: uppercase;
    color: var(--light-black);
  }

  input, textarea {
    width: 33vw;
    margin-bottom: 1.5rem;
  }

  button {
    background-color: var(--greyish-gradient);
    border: var(--greyish-gradient);
    color: var(--light-black);
    box-shadow: 6px 6px 12px rgba(98, 132, 172, 0.1), -7px -6px 10px rgba(255, 255, 255, 0.2);
  }
`


const StyledInput = styled.input.attrs({
  className: 'NeumorphicInput'
})`
  height: 4.5rem;
  background-color: var(--greyish-gradient);
  border-radius: 15px;
  font-size: 1.25rem;
  padding-left: 1.25rem;

  transition: box-shadow 100ms;

  box-shadow: 6px 6px 12px rgba(98, 132, 172, 0.1), -7px -6px 10px rgba(255, 255, 255, 0.2);

  :focus {
    box-shadow: none;
  }
`

const StyledTextArea = styled.textarea.attrs({
  className: 'NeumorphicTextArea'
})`
  height: 7rem;
  background-color: var(--greyish-gradient);
  border-radius: 15px;
  font-size: 1.25rem;
  padding-left: 1.25rem;
  padding-top: 1.25rem;

  transition: box-shadow 100ms;

  box-shadow: 6px 6px 12px rgba(98, 132, 172, 0.1), -7px -6px 10px rgba(255, 255, 255, 0.2);

  :focus {
    box-shadow: none;
  }
`

function Input({
  placeholder="placeholder",
  type="text",
  value
}) {
  return <StyledInput type={type} placeholder={placeholder} value={value} />
}

function Textarea({
  placeholder = "placeholder",
  type = "text",
  value
}) {
  return <StyledTextArea placeholder={placeholder} value={value} />
}

export default function Contact() {
  return (
    <Wrapper>
      <section className="Left">
        <h1>Let's talk about how AI can help your business.</h1>
      </section>
      <section className="Right">
        <form>
          <Input placeholder="Name" />
          <Input placeholder="Email" />
          <Textarea placeholder="Message" />
          <Button>Send</Button>
        </form>
      </section>
    </Wrapper>
  )
}

