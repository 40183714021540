import "../styles/sanitize.css"
import "../styles/global.css"

import React from 'react'

import Layout from 'src/components/Layout'
import Footer from 'src/components/Footer'
import GradientBG from 'src/components/GradientBG'

import Intro from 'src/containers/Intro'
import Contact from 'src/containers/Contact'


export default ({
  history,
  location,
  match
}) => {
  return (
    <Layout
      history={history}
      location={location}
      match={match}
    >

      <Intro />

      <GradientBG>

        {/* TODO: TECH BLOCK */}

        {/* TODO: CUSTOMERS */}

        {/* TODO: TEAM BLOCK */}

        {/* <Contact /> */}


        {/* <Footer>
          © Automation.co. All rights reserved.
        </Footer> */}

      </GradientBG>

    </Layout>
  )
}
