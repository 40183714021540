import styled from 'styled-components'

export default styled.div.attrs({
  className: 'Footer'
})`
  text-align: center;
  color: var(--light-black);
  font-weight: 300;
  padding-bottom: 5vh;
`

