import React from 'react'
import styled from 'styled-components'

import Button from 'src/components/Button'

import LOGO from 'src/assets/img/automation-logo.svg'

const Wrapper = styled.div.attrs({
  className: "IntroWrapper",
})`
  position: relative;
  height: 100vh;
  width: 100vw;
  background-image: linear-gradient(to right bottom, rgba(15, 94, 245, 1), rgba(6, 48, 232, 1));
  padding-top: 37vh;
  padding-left: 9vw;
  overflow: auto;

  h1 {
    font-family: 'Montserrat Alternates';
    font-size: 7.5625rem;
    font-weight: 600;
    color: #fff;
  }

  h2 {
    font-size: 2.8rem;
    color: #fff;
    font-weight: 400;
    margin-bottom: 1.6rem;
  }
`

const Logo = styled.img.attrs({
  className: 'Logo'
})`
  margin-right: 1rem;
  height: 5rem;
`

export default function Intro() {
  return (
    <Wrapper>

      <h1>
        <Logo src={LOGO} />
        automation.co
      </h1>
      <h2>
        Software and APIs Powered by Deep Learning AI
      </h2>
      {/* <Button>Contact Us</Button> */}
    </Wrapper>
  )
}
