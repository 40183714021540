import React from "react"
import styled from "styled-components"
import noop from "lodash/noop"

const StyledButton = styled.button.attrs({
  className: "NeumorphicButton",
})`
  height: 3.5rem;
  padding: 1.2rem 2.875rem;
  border-radius: 28px;

  cursor: pointer;

  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;

  letter-spacing: 0.2;

  transition: box-shadow 500ms;

  background-color: #0c4ef1;
  border: 1px solid rgba(34, 102, 251, 0.7);
  box-shadow: 4px 4px 14px rgba(21, 24, 32, 0.23),
    -4px -4px 14px rgba(223, 235, 246, 0.18);

  :hover {
    box-shadow: none;
  }
`

export default function Button({ onClick = noop, children }) {
  return <StyledButton onClick={onClick}>{children}</StyledButton>
}
